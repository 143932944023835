// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';

// Misc / utils
import * as S from './styles';

const Radio = React.forwardRef(
  (
    {
      ariaLabel,
      children,
      className = '',
      disabled,
      icon,
      iconAriaAltText = '',
      iconHeight = '12px',
      iconWidth = '12px',
      id,
      isChecked = false,
      name, // required to group radio buttons in same tab group
      onChange,
      value,
      ...rest
    },
    ref,
  ) => (
    <S.Wrapper className={className}>
      <S.Input
        aria-checked={isChecked}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        checked={isChecked}
        disabled={disabled}
        id={id}
        key={id}
        name={name}
        type="radio"
        value={value}
        ref={ref}
        onChange={onChange}
        {...rest}
      />
      <S.Label htmlFor={id} value={value} disabled={disabled}>
        {icon && <S.Icon src={icon} width={iconWidth} height={iconHeight} alt={iconAriaAltText} />}
        {children}
      </S.Label>
    </S.Wrapper>
  ),
);

export default Radio;
